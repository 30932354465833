import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/layout";

const NotFoundPage = () => (
    <Layout>
        <div className={"not-found"}>
            <h1>Stránka nenalezena</h1>
        </div>
    </Layout>
);

export default NotFoundPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
